import CalculatorBase, { CalculatorOptions } from '../CalculatorBase'
import PaymentFrequency from '../PaymentFrequency'
import Mortgage from './Mortgage'

interface MortgageCalculatorOptions extends CalculatorOptions {
  downpaymentPercentage: number,
  loanAmountSelector: string,
  downpaymentSelector: string,
  paymentFrequencySelector: string,
  interestSelector: string,
  amortizationPeriodSelector: string,
}

export default class MortgageCalculator extends CalculatorBase {
  options: MortgageCalculatorOptions

  defaultOptions: MortgageCalculatorOptions = {
    formSelector: '#mortgageCalculator',
    resultSelector: '#mortgageCalculatorResult',
    resultVisibilityClass: 'invisible',
    downpaymentPercentage: 0.1,
    loanAmountSelector: 'input[name=loanAmount]',
    downpaymentSelector: 'input[name=downpayment]',
    paymentFrequencySelector: 'select[name=paymentFrequency]',
    interestSelector: 'input[name=interest]',
    amortizationPeriodSelector: 'input[name=amortizationPeriod]',
  }

  payment = 0.0
  propertyPrice = 0.0

  constructor(options: Partial<MortgageCalculatorOptions> = {}) {
    super()
    this.options = { ...this.defaultOptions, ...options }
  }

  override init(): void {
    this.registerFormSubmitHandler()
    this.tryAutoCalculate()
  }

  calculate(): void {
    const mortgage = new Mortgage({
      loanAmount: this.loanAmount,
      downpayment: this.numberFromSelector(this.options.downpaymentSelector),
      interest: this.numberFromSelector(this.options.interestSelector),
      amortizationPeriod: this.numberFromSelector(this.options.amortizationPeriodSelector),
      paymentFrequency: this.paymentFrequency
    })

    this.payment = mortgage.payment
  }

  updateUi(): void {
    this.resultElement.innerHTML = `<strong>${this.formatCurrency(this.payment)}</strong> / <small>${this.paymentFrequency}<sup>*</sup></small>`
    this.showResult()
  }

  // Look for a `data-property-price` element on the form. If found, automatically calculate the mortgage
  tryAutoCalculate() {
    const propertyPrice = this.form?.dataset.propertyPrice

    if (propertyPrice) {
      this.propertyPrice = this.numerifyString(propertyPrice)

      const downpayment = this.propertyPrice * this.options.downpaymentPercentage
      const loanAmountInput = this.form?.querySelector(this.options.loanAmountSelector) as HTMLInputElement
      const downpaymentInput = this.form?.querySelector(this.options.downpaymentSelector) as HTMLInputElement

      loanAmountInput.value = this.formatCurrency(this.propertyPrice, { includeSymbol: false })
      downpaymentInput.value = this.formatCurrency(downpayment, { includeSymbol: false })

      this.calculate();
      this.updateUi();
    }

  }

  get loanAmount(): number {
    // if `propertyPrice` is set, this means we can auto-calculate by getting the price directly from the form
    // `data-property-price` attribute
    if (this.propertyPrice > 0) {
      return this.propertyPrice
    } else {
      return this.numberFromSelector(this.options.loanAmountSelector)
    }
  }

  get paymentFrequency(): PaymentFrequency {
    return (this.valueForSelector(this.options.paymentFrequencySelector)) as PaymentFrequency ?? PaymentFrequency.Monthly
  }
}
