import BootstrapConfig from './BootstrapConfig'
import MortgageCalculator from './calculators/mortgage-calculator/MortgageCalculator'
import PurchasePowerCalculator from './calculators/PurchasePowerCalculator'
import LegalFeesCalculator from './calculators/LegalFeesCalculator'
import FavoritesManager from './favorites/FavoritesManager'

window.Sevenview = {}

BootstrapConfig.init()

window.Sevenview.FavoritesManager = new FavoritesManager()
window.Sevenview.FavoritesManager.init()

new LegalFeesCalculator({
  formSelector: '#legalFeesCalculator'
}).init()

new PurchasePowerCalculator({
  formSelector: '#purchasePowerCalculator',
}).init()

new MortgageCalculator({
  formSelector: '#mortgageCalculator'
}).init()
