import CalculatorBase, { CalculatorOptions } from './CalculatorBase'

interface LegalFeesCalculatorOptions extends CalculatorOptions {
  sellingPriceSelector: string,
  percentage: number,
}

export default class LegalFeesCalculatorTS extends CalculatorBase {
  options: LegalFeesCalculatorOptions

  defaultOptions: LegalFeesCalculatorOptions = {
    formSelector: '#legalFeesCalculator',
    resultSelector: '#legalFeesCalculatorResult',
    resultVisibilityClass: 'd-none',
    sellingPriceSelector: 'input[name=sellingPrice]',
    percentage: 0.015,
  }

  fee = 0.0

  constructor(options: Partial<LegalFeesCalculatorOptions> = {}) {
    super()
    this.options = { ...this.defaultOptions, ...options  }
  }

  calculate(): void {
    const sellingPriceElement = this.form?.querySelector(this.options.sellingPriceSelector) as HTMLInputElement
    const sellingPrice = this.numerifyString(sellingPriceElement.value)
    this.fee = sellingPrice * this.options.percentage
  }

  updateUi(): void {
    this.resultElement.innerHTML = `<h3>${this.formatCurrency(this.fee)}</h3>`
    this.showResult()
  }
}
