export default class Favorite {
  constructor(mls, addedAt) {
    this._mls = mls
    this._addedAt = addedAt
  }

  get mls() {
    return this._mls
  }

  get addedAt() {
    return this._addedAt
  }

  toJSON() {
    return {
      mls: this._mls,
      addedAt: this._addedAt,
    }
  }
}
