export default class FavoriteProperty {
  constructor(options) {
    options = options || {}
    this.mls = options.mls
    this.price = options.price
    this.city = options.city
    this.streetAddress = options.streetAddress
    this.thumbnailUrl = options.thumbnailUrl
    this.link = `/properties/${options.id}`
    this.isLease = options.isLease
    this.lease = options.lease
    this.leasePerUnit = options.leasePerUnit
    this.leasePerTime = options.leasePerTime
    this.addedAt = options.addedAt
  }

  get formattedPrice() {
    if (this.isLease) {
      let leaseText = this.formatCurrency(this.lease)
      if (this.leasePerUnit) {
        leaseText += ` / ${this.leasePerUnit
          .toLowerCase()
          .replace('square feet', 'sqft')}`
      }
      if (this.leasePerTime) {
        leaseText += ` / ${this.leasePerTime
          .toLowerCase()
          .replace('yearly', 'yr.')
          .replace('monthly', 'mo.')}`
      }
      return leaseText
    } else {
      return this.formatCurrency(this.price)
    }
  }

  get formattedAddedAt() {
    return new Intl.DateTimeFormat('en-CA', {
      dateStyle: 'medium',
      timeStyle: 'short',
    }).format(this.addedAt)
  }

  formatCurrency(amount) {
    return Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(amount)
  }
}
