import CalculatorBase, { CalculatorOptions } from './CalculatorBase'
import PaymentFrequency from './PaymentFrequency'

interface PurchasePowerCalculatorOptions extends CalculatorOptions {
  desiredPaymentSelector: string,
  paymentFrequencySelector: string,
  amortizationPeriodSelector: string,
  expectedInterestSelector: string,
  resultWrapperSelector: string,
}

export default class PurchasePowerCalculator extends CalculatorBase {
  options: PurchasePowerCalculatorOptions

  defaultOptions: PurchasePowerCalculatorOptions = {
    formSelector: '',
    resultSelector: '#purchasePowerCalculatorResult',
    resultVisibilityClass: 'd-none',
    desiredPaymentSelector: 'input[name=desiredPayment]',
    paymentFrequencySelector: 'select[name=paymentFrequency]',
    amortizationPeriodSelector: 'input[name=amortizationPeriod]',
    expectedInterestSelector: 'input[name=expectedInterest]',
    resultWrapperSelector: '#purchasePowerCalculatorResultWrapper',
  }

  payment = 0.00

  constructor(options: Partial<PurchasePowerCalculatorOptions> = {}) {
    super()
    this.options = { ...this.defaultOptions, ...options }
  }

  calculate(): void {
    const interest = this.expectedInterest / 100
    const monthlyInterest = (1 + interest / 2) ** (1.0 / 6) - 1
    const oi = (1 + monthlyInterest) ** (12 * this.amortizationPeriod)

    let f = 0
    switch (this.paymentFrequency) {
      case PaymentFrequency.BiMonthly:
        f = (this.desiredPayment * 6) / 12
        break
      case PaymentFrequency.Monthly:
        f = this.desiredPayment
        break
      case PaymentFrequency.SemiMonthly:
        f = (this.desiredPayment * 24) / 12
        break
      case PaymentFrequency.BiWeekly:
        f = (this.desiredPayment * 26) / 12
        break
      case PaymentFrequency.Weekly:
        f = (this.desiredPayment * 52) / 12
        break
    }

    this.payment = (f * (oi - 1)) / oi / monthlyInterest
  }

  updateUi(): void {
    this.resultElement.innerHTML = `<h3>${this.formatCurrency(this.payment)}</h3>`
    this.showResult({ resultElement: this.resultWrapperElement })
  }

  get expectedInterest(): number {
    return this.numerifyString(this.valueForSelector(this.options.expectedInterestSelector))
  }

  get amortizationPeriod(): number {
    return this.numerifyString(this.valueForSelector(this.options.amortizationPeriodSelector))
  }

  get paymentFrequency(): string {
    return this.valueForSelector(this.options.paymentFrequencySelector) || 'Monthly'
  }

  get desiredPayment(): number {
    return this.numerifyString(this.valueForSelector(this.options.desiredPaymentSelector))
  }

  get resultWrapperElement(): HTMLElement {
    return document.querySelector(this.options.resultWrapperSelector) as HTMLElement
  }
}
