export interface CalculatorOptions {
  formSelector: string
  resultSelector: string
  resultVisibilityClass: string
}

export default abstract class CalculatorBase {
  abstract options: CalculatorOptions
  abstract defaultOptions: CalculatorOptions

  registerFormSubmitHandler(): void {
    this.form?.addEventListener('submit', (event) => {
      event.preventDefault()
      this.calculate()
      this.updateUi()
    })
  }

  init(): void {
    this.registerFormSubmitHandler()
  }

  abstract calculate(): void
  abstract updateUi(): void

  numerifyString(val: string): number {
    if (val === '') return 0
    return parseFloat(val.replace(/[^\d.]/g, ''))
  }

  get form(): HTMLFormElement | null {
    return document.querySelector(this.options.formSelector as string)
  }

  formatCurrency(amount: number, options: { includeSymbol?: boolean } = {}) {
    const includeSymbol = options.includeSymbol !== false

    if (includeSymbol) {
      return Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
      }).format(amount)
    } else {
      return Intl.NumberFormat('en-CA', {
        useGrouping: false,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount)
    }
  }

  valueForSelector(selector: string) {
    const element = this.form?.querySelector(selector) as HTMLInputElement
    return element.value
  }

  numberFromSelector(selector: string) {
    return this.numerifyString(this.valueForSelector(selector))
  }

  get resultElement(): HTMLElement {
    return document.querySelector(this.options.resultSelector) as HTMLElement
  }

  showResult(options: { resultElement?: HTMLElement } = {}) {
    const element = options.resultElement ?? this.resultElement
    element.classList.remove(this.options.resultVisibilityClass)
  }
}
