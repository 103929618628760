import Favorite from './Favorite.js'
import FavoritePropertyService from './FavoritePropertyService.js'

export default class FavoritesManager {
  _favorites = []

  FAVORITES_BADGE_ELEMENT_ID = 'rp-favorites-badge'
  ICON_SELECTOR = '.rp-favorite>i'
  ICON_DATASET_ATTRIBUTE = 'propertyMls'
  LOCAL_STORAGE_NAME = 'rp:favorites'
  API_ENDPOINT = '/properties/mls'

  constructor() {
    this.fetchOrCreateFavorites()
    this.updateFavoritesCountBadge()
    this.initializeFavoritesOnPage()
  }

  init = () => {
    if (document.querySelector('.rp-favorites')) {
      this.populateFavoritesOnPage()
    }
  }

  fetchOrCreateFavorites() {
    const favoritesFromLocalStorage = localStorage.getItem(
      this.LOCAL_STORAGE_NAME
    )
    if (favoritesFromLocalStorage) {
      const parsedFavorites = JSON.parse(favoritesFromLocalStorage)
      this._favorites = parsedFavorites.map(
        (f) => new Favorite(f.mls, f.addedAt)
      )
    } else {
      this.persistFavorites()
    }
  }

  updateFavoritesCountBadge() {
    const favoritesBadge = document.getElementById(
      this.FAVORITES_BADGE_ELEMENT_ID
    )
    if (this.favoritesCount > 0) {
      favoritesBadge.innerHTML = this.favoritesCount.toString()
      favoritesBadge.hidden = false
    } else {
      favoritesBadge.innerHTML = '0'
      favoritesBadge.hidden = true
    }
  }

  initializeFavoritesOnPage() {
    const icons = document.querySelectorAll(this.ICON_SELECTOR)
    icons.forEach((icon) => {
      const propertyMls = icon.dataset[this.ICON_DATASET_ATTRIBUTE]
      if (this._favorites.some((f) => f.mls === propertyMls)) {
        this.setFavorited(icon)
      }
    })
  }

  toggleFavorite(element) {
    const propertyMls = element.dataset[this.ICON_DATASET_ATTRIBUTE]
    const icon = element.querySelector('i')
    const favorite = new Favorite(propertyMls, Date.now())

    if (this._favorites.some((f) => f.mls === propertyMls)) {
      this.removeFavorite(favorite)
      this.unsetFavorited(icon)
    } else {
      this.addFavorite(favorite)
      this.setFavorited(icon)
    }
  }

  removeFavorite(favorite) {
    this.removeFavoriteByMls(favorite.mls)
  }

  removeFavoriteByMls(mls) {
    this._favorites = this._favorites.filter((f) => f.mls !== mls)
    this.persistFavorites()
  }

  addFavorite(favorite) {
    this._favorites.push(favorite)
    this.persistFavorites()
  }

  setFavorited(element) {
    element.classList.add('fas', 'text-secondary')
    element.classList.remove('far')
    this.updateFavoritesCountBadge()
  }

  unsetFavorited(element) {
    element.classList.add('far')
    element.classList.remove('fas', 'text-secondary')
    this.updateFavoritesCountBadge()
  }

  persistFavorites() {
    localStorage.setItem(
      this.LOCAL_STORAGE_NAME,
      JSON.stringify(this._favorites)
    )
  }

  get favorites() {
    return [...this._favorites]
  }

  get favoritesCount() {
    return this._favorites.length
  }

  async populateFavoritesOnPage() {
    const spinnerNode = document.querySelector('.rp-spinner')

    // If there are no favorites stored in localstorage, return immediately
    if (this.favorites.length < 1) {
      this.showNoFavoritesHtml()
      return
    } else {
      spinnerNode.hidden = false
    }

    const favoritePropertyService = new FavoritePropertyService()
    const favoriteProperties = await favoritePropertyService.fetch(
      this._favorites,
      this
    )

    // check if there are no favorites returned after the lookup has been performed
    if (favoriteProperties.length < 1) {
      this.showNoFavoritesHtml()
      return
    }

    favoriteProperties.forEach((favoriteProperty) => {
      const favoritesContainer = document.getElementById('rp-favorites')
      const gridItemTemplate = document.getElementById(
        'rp-favorite-property-template'
      )
      const propertyNode = gridItemTemplate.content.cloneNode(true)

      const rootNode = propertyNode.querySelector('.rp-favorite-property')
      rootNode.id = `rp-favorite-property-${favoriteProperty.mls}`

      if (favoriteProperty.thumbnailUrl) {
        const imageNode = propertyNode.querySelector(
          '.rp-favorite-property__image'
        )
        imageNode.src = favoriteProperty.thumbnailUrl
      }

      const priceNode = propertyNode.querySelector(
        '.rp-favorite-property__price'
      )
      priceNode.innerHTML = favoriteProperty.formattedPrice

      const addressNode = propertyNode.querySelector(
        '.rp-favorite-property__address'
      )
      addressNode.innerHTML = `${favoriteProperty.streetAddress}, ${favoriteProperty.city}`

      const linkNode = propertyNode.querySelector('.rp-favorite-property__link')
      linkNode.href = favoriteProperty.link

      const infoNode = propertyNode.querySelector('.rp-favorite-property__info')
      infoNode.innerHTML = `${favoriteProperty.mls} added ${favoriteProperty.formattedAddedAt}`

      const deleteButtonNode = propertyNode.querySelector(
        '.rp-favorite-property__delete_button'
      )
      deleteButtonNode.dataset.propertyMls = favoriteProperty.mls

      spinnerNode.hidden = true

      favoritesContainer.append(propertyNode)
    })
  }

  showNoFavoritesHtml() {
    const noFavoritesNode = document.getElementById('rp-no-favorites')
    const spinnerNode = document.querySelector('.rp-spinner')

    noFavoritesNode.hidden = false
    spinnerNode.hidden = true
  }

  deleteFavorite(element) {
    const mls = element.dataset.propertyMls
    this.removeFavoriteByMls(mls)

    // Remove the element from the page
    const favoriteProperty = document.getElementById(
      `rp-favorite-property-${mls}`
    )
    favoriteProperty.remove()
    this.updateFavoritesCountBadge()

    // Check to see if there are no favorites left
    if (this.favoritesCount < 1) {
      this.showNoFavoritesHtml()
    }
  }
}
