import PaymentFrequency from "../PaymentFrequency"

interface MortgageOptions {
  loanAmount: number,
  downpayment: number,
  interest: number,
  amortizationPeriod: number,
  paymentFrequency: PaymentFrequency,
}

export default class Mortgage {
  loanAmount: number
  downpayment: number
  interest: number
  amortizationPeriod: number
  paymentFrequency: PaymentFrequency

  constructor(options: MortgageOptions) {
    this.loanAmount = options.loanAmount
    this.downpayment = options.downpayment
    this.interest = options.interest / 100
    this.amortizationPeriod = options.amortizationPeriod
    this.paymentFrequency = options.paymentFrequency
  }

  get payment() {
    return this.calculate()
  }

  calculate() {
    const principal = this.loanAmount - this.downpayment

    const monthlyInterest = (1 + this.interest / 2) ** (1.0 / 6) - 1

    const m =
      (principal *
        monthlyInterest *
        (1 + monthlyInterest) ** (12 * this.amortizationPeriod)) /
      ((1 + monthlyInterest) ** (12 * this.amortizationPeriod) - 1)

    const y = m * 12

    let payment
    switch (this.paymentFrequency) {
      case PaymentFrequency.BiMonthly:
        payment = y / 6
        break
      case PaymentFrequency.Monthly:
        payment = m
        break
      case PaymentFrequency.SemiMonthly:
        payment = y / 24
        break
      case PaymentFrequency.BiWeekly:
        payment = y / 26
        break
      case PaymentFrequency.Weekly:
        payment = y / 52
        break
    }

    return payment
  }
}
