import FavoriteProperty from './FavoriteProperty.js'

export default class FavoritePropertyService {
  API_ENDPOINT = '/properties/mls'

  _favoriteProperties = []

  async fetch(favorites, favoritesManager) {
    const favoriteProperties = []
    this.favoritesManager = favoritesManager

    for (const favorite of favorites) {
      const endpoint = `${this.API_ENDPOINT}/${favorite.mls}.json`
      const response = await fetch(endpoint)

      switch (response.status) {
        case 200:
          const data = await response.json()
          const property = data.property
          const favoriteProperty = new FavoriteProperty({
            id: property.id,
            mls: property.mls,
            price: property.price,
            streetAddress: property.address.street_address,
            city: property.address.city,
            thumbnailUrl: property.thumbnail_url,
            isLease: property.is_lease,
            lease: property.lease,
            leasePerUnit: property.lease_per_unit,
            leasePerTime: property.lease_per_time,
            addedAt: favorite.addedAt,
          })
          favoriteProperties.push(favoriteProperty)
          break
        case 404:
          // if property not found, remove it from favorites
          this.favoritesManager.removeFavoriteByMls(favorite.mls)
          this.favoritesManager.updateFavoritesCountBadge()
          break
        default:
          // log any other error, but keep the favorite
          console.error(
            'Unexpected error fetching property with MLS',
            favorite.mls
          )
      }
    }

    return favoriteProperties.sort((a, b) => b.addedAt - a.addedAt)
  }
}
